/*
 * 统一埋点上报入口
 * @Author: shuo 
 * @Date: 2019-06-26 10:03:55 
 * @Last Modified by: shuo
 * @Last Modified time: 2020-05-13 17:09:27
 */
export default {
  report({ sa = null, ga = null } = {}) {
    if (!!sa) this._sendSa(sa)
    if (!!ga) {
      if (window.GA_REMOVE_TIP) {
        window.GA_REMOVE_TIP('GA report config')
      }
    }
  },

  _sendSa({ name = null, param = null, beacon = null, image = null, page_param, page_id, tab_page_id, page_name = null } = {}) {
    if (!name) return
    let data = {
      activity_name: name,
      activity_param: param || {},
    }
    if (page_param) data['page_param'] = page_param
    if (page_name) data['page_name'] = page_name
    if (page_id) data['page_id'] = page_id
    if (tab_page_id) data['tab_page_id'] = tab_page_id
    sa('send', data, { beacon: beacon ? 1 : 0, image: image ? 1 : 0 })
  },
}
