/**
 * @file 业务监控 公共部分
 */

import { isString } from '@shein/common-function'
import { SIMetric } from 'public/src/pages/common/monitor/index'

/**
 * 页面核心接口是否正常响应
 * @param {string} tags.page 
 * @param {string} tags.status 
 */
const metricPageSuccess = (tags, params) => {
  const { page, status } = tags || {}

  if (!isString(page) || !isString(status)) return console.warn('page or status is not string')

  const statusMsg = status === '1' ? 'successfully' : 'failed'

  SIMetric.metricCount({ 
    metric_name: 'page_success_total', 
    tags, 
    params,
    message: `page opens ${statusMsg} in ${page}`
  })
}
/**
 * CCC组件点击
 * tags.page：页面名称
 * tags.component_type：组件类型style
 * tags.position: 组件的位置(从1开始 与埋点一致)
 *  */ 
const metricCccClick = (tags) => {
  const { page, component_type, position } = tags || {}
  
  if (!isString(page) || !isString(component_type) || !isString(position)) return console.warn('page or component_type or position is not string')

  SIMetric.metricCount({ 
    metric_name: 'ccc_block_main_click_total', 
    tags, 
    message: `${component_type} components click on the ${position} position of ${page}`
  })
}
export {
  metricPageSuccess,
  metricCccClick,
}
