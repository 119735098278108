/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import UserKey from '../UserKey'
import { getCookie } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { SUPPORT_LANGS, lang } = gbCommonInfo
const COOKIE_KEY = 'language'

class Language extends UserKey {
  static canHandle(key) {
    return key === 'language'
  }

  get() {
    //单语言站点的SUPPORT_LANGS为空数组，所以空数组时读lang，注意se站默认语种是sesv
    //先设置默认语种
    let value = SUPPORT_LANGS[0] || lang
    let cookieValue = getCookie(COOKIE_KEY)
    if( cookieValue && SUPPORT_LANGS.includes(cookieValue) ){
      value = cookieValue 
    }
    return value
  }
  async set(value, {} = {}) {
    await schttp({
      url: '/api/common/language/update',
      params: {
        new_lang: value
      }
    })
    return value
  }
}

export default Language
