export default class Event {
  constructor ({
    controlCenter = null,
    publisher = null,
    subscriber = null
  }) {
    // create channel control center
    this.controlCenter = controlCenter
    // create publisher
    this.publisher = publisher
    // create subscribe
    this.subscriber = subscriber
    
    this.eventAttributeMap = {
      'click': 'da-event-click',
      'touchstart': 'da-event-touchstart',
      'focus': 'da-event-focus',
      'blur': 'da-event-blur',
    }
    // click 
    this._bindEvent({ event: 'click', attribute: this.eventAttributeMap['click'] })
    // touchstart
    this._bindEvent({ event: 'touchstart', attribute: this.eventAttributeMap['touchstart'] })
    // focus
    this._bindEvent({ event: 'focus', attribute: this.eventAttributeMap['focus'], useCapture: true })
    // blur
    this._bindEvent({ event: 'blur', attribute: this.eventAttributeMap['blur'], useCapture: true })
  }

  _bindEvent ({ event = '', attribute = '', useCapture = false } = {}) {
    if (typeof document === 'undefined') return 
    document && document.addEventListener(event, e => {
      if (!attribute || e.target === document) return
      let target = e.target.closest(`[${attribute}]`)
      if (!target) return
      let daId = target.getAttribute(attribute)
      this.publisher.notify({ daId, sendType: 'event', target: target })
    }, useCapture)
  }

  bindEventByDaId ({ event = '', daId = '' } = {}) {
    if (!event || !daId) return

    const elements = document.querySelectorAll(`[${this.eventAttributeMap[event]}="${daId}"]`)

    if(elements && elements.length > 0){
      elements.forEach(element => {
        element.addEventListener(event, (e) => {
          this.publisher.notify({ daId, sendType: 'event', target: e.target })
        })
      })
    }else{
      console.log('No elements found with the provided attribute.');
    }
  }
}
