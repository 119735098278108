import abtServer from './core/index.js'
import { abtRouter } from './abtRouter.js'
import { abtDebugInstance } from './abtDebug.js'

function getABTServiceInstance () {
  if (typeof window === 'undefined') return abtServer()
  if (!window._abt_server_provider) {
    window._abt_server_provider = abtServer()
    abtRouter(window._abt_server_provider)
    abtDebugInstance(window._abt_server_provider)
  }
  
  return window._abt_server_provider
}
const abtservice = getABTServiceInstance()

export {
  abtservice,
}
