// 列表相关页面
// 真实、选品、搜索、new、pick, 店铺、分类、品牌 等

export default [
  'page_real_class',
  'page_select_class',
  'page_search',
  'page_picked_goods_list_goodslistsmallcell',
  'page_picked_goods_list_glcol2cell',
  'page_goods_group',
  'page_daily_new',
  'page_shein_picks',
  'page_selling_point',
  'page_store',
].map(pageName => {
  return {
    pageName,
    newPosKeys: [
      'FollowLabel',
      'NewInSortJson',
      'listwithCoupon', // 商卡到手价样式
      'listwithS3', // S3会员价格展示优化
      'listpriceTimeTag' // 到手价倒计时
    ],
    posKeys: [
      'ListAttrSequence',
      'CategoryRecommendationsForYou',
      'VideoIcon',
      'HideGoodsNum',
      'SpuPic',
      'SearchPageSort',
      'SearchRecommendationsForYou',
      'SearchFeedHotword',
      'SearchNoResultFeedHotword',
      'SearchRecTips',
      'brandstoreobm',
      'brandstore',
    ],
  }
})
