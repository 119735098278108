/**
 * PromotionIcon 推全 ShowPromotionIcon
 */
export default {
  pageName: 'page_store_reviews',
  newPosKeys: [
    'PointProgram'
  ],
  posKeys: [],
}
