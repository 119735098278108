/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import UserKey from '../UserKey'
import { setLocalStorage, getLocalStorage } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

class Location extends UserKey {
  static canHandle(key) {
    return key === 'location'
  }

  static cacheQue = []

  async get () {
    // 先从客户端缓存里面取
    let locationCache = getLocalStorage('location') || ''
    if (locationCache == 'Null') return ''
    // 客户端取不到的话，再从服务端session里面取
    if (!locationCache) {
      locationCache = await this.getServiceCache()
    }
    if (locationCache) return locationCache
    return ''
  }
  
  getSync() {
    // 先从客户端缓存里面取
    let locationCache = getLocalStorage('location')
    if (locationCache) return locationCache
    return ''
  }

  async set(value) {
    const res = await this.setSerivceCache(value)
    if (res?.code == '0') {
      // 同步设置客户端缓存
      setLocalStorage({
        key: 'location',
        value
      })
    }
  }

  // 设置服务端的缓存
  async setSerivceCache (value) {
    const data = {
      key: 'location',
      actionType: 'Location/setLocation',
      value
    }
    const res = await schttp({
      url: '/api/common/userInfoManager/update',
      method: 'POST',
      data
    })
    return res
  }

  // 获取服务端的缓存
  async getServiceCache () {
    const res = await schttp({
      url: '/api/common/userInfoManager/get',
      method: 'GET',
      params: {
        key: 'location',
        actionType: 'Location/getLocation'
      }
    })
    if (res?.code == '0') {
      setLocalStorage({
        key: 'location',
        value: res?.data || 'Null'
      })
      return res?.data || ''
    }
  }
}

export default Location
