// 分类页

export default [
  {
    pageName: 'page_category',
    posKeys: [],
    newPosKeys: [],
  },
  {
    pageName: 'page_activity_factory',
    newPosKeys: [],
    posKeys: ['ActHot', 'ActReview', 'ActFavorite'],
  },
]
