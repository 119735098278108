import {
  instanceAbtRouter,
  triggerAbtRouter,
} from '@shein-aidc/basis-abt-router'
import commonAppPoskey from './config/common.js'
import options from './config/index'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'

export const abtRouter = abtservice => {
  instanceAbtRouter({
    abtServer: abtservice,
    options,
    newPosKeys: commonAppPoskey.newPosKeys,
    posKeys: commonAppPoskey.posKeys,
    preloadModel: false,
  })

  let loadEnd = null
  function findPageInfo(callback) { 
    if (loadEnd) return 

    if (window.hasOwnProperty('SaPageInfo')) {
      callback(window.SaPageInfo.page_name)
      loadEnd = true
      return
    }

    requestAnimationFrame(() => {
      findPageInfo(callback)
    })
  }

  findPageInfo((pageName) => {
    triggerAbtRouter({ routeName: pageName })
  })

  onWindowLoad(() => {
    if (loadEnd) return 

    loadEnd = true // 结束上面的循环

    // 防止页面加载过慢，导致SaPageInfo未赋值
    if (!window?.SaPageInfo?.page_name) {
      console.error('SaPageInfo.page_name is undefined, please check!')
      triggerAbtRouter({ routeName: '' })
    }
  })
}
