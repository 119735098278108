import { parseQueryString, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

/**
 * @param {object} abtParams 
 * @param {string} key 
 * @param {boolean} isRomwe 
 * @returns 
 */
export function getAbtParams(abtParams = {}, key = '', isRomwe = false, isParse = true) {
  if (Object.keys(abtParams).length > 0) {
    const prefix = isRomwe ? 'RPc' : 'SPc'
    let posKey = prefix + key

    if (/^(Sh|Rw)/.test(posKey)) {
      posKey = isRomwe ? posKey.replace(/(Rw)(\w*)(pc)/, '$2') : posKey.replace(/(Sh)(\w*)(Pc)/, '$2')
    }
    let abtTypeId = abtParams[posKey] && abtParams[posKey].p
    if (!abtTypeId) abtTypeId = abtParams[`${prefix}ActDefault`] && abtParams[`${prefix}ActDefault`].p
    if (abtTypeId && typeof abtTypeId === 'string') {
      if (isParse) {
        return parseQueryString(abtTypeId)
      } else {
        return abtTypeId
      }
    }
  }
  return ''
}

export const handleColor = (str) => {
  if (!str) return ''
  if (str.length === 9) {
    return str[0].concat(str.slice(3, 9), str.slice(1, 3))
  }
  return str
}

export const timeZone = () => {
  // 时区
  const date = new Date()
  const timeZone = date.getTimezoneOffset() / 60
  return `GMT${timeZone > 0 ? '-' : '+'}${Math.abs(timeZone)}`
}

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') { return a / 255 }
  if ((typeof alpha != 'number') || alpha < 0 || alpha > 1){
    return 1
  }
  return alpha
}

/**
 *  const c1 = "#f80"
    const c2 = "#f808"
    const c3 = "#0088ff"
    const c4 = "#0088ff88"
    const c5 = "#98736"
    console.log(hexToRGBA(c1))   //  rgba(255, 136, 0, 1)
    console.log(hexToRGBA(c2))   //  rgba(255, 136, 0, 0.53125)
    console.log(hexToRGBA(c3))   //  rgba(0, 136, 255, 1)
    console.log(hexToRGBA(c4))   //  rgba(0, 136, 255, 0.53125)
    console.log(hexToRGBA(c5))   //  Uncaught Error: Invalid HEX
    console.log(hexToRGBA(c1, 0.5))   //  rgba(255, 136, 0, 0.5)
    console.log(hexToRGBA(c3, 0.5))   //  rgba(0, 136, 255, 0.5)
 * @param {String} hex 十六进制字符串
 * @param {Number} alpha 0 ~ 1
 * @returns 
 */
export const hexToRGBA = (hex, alpha) => {
  if (!/^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)) { throw new Error('Invalid HEX') }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr =  hex.slice(1)?.match(new RegExp(`.{${chunkSize}}`, 'g'))
  const [r, g, b, a] = hexArr.map((hexStr) => {
    return parseInt(hexStr.repeat(2 / hexStr.length), 16)
  })
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}

/**
 * 设置懒加载组件容器的占位高度
 * 防止抖动
 * @param {*} data 
 * @returns 
 */
export const getComponentHeight = (data) => {
  const { __comp_name, styleType, componentKey, props: { items = [], name = '' } } = data || {}
  const getRenderAttrs = (style = {}) => {
    return {
      // 锚点需要 （@aojian）
      attrs: {
        'data-component-name': name
      },
      style,
    }
  }
  // 针对商品组件/优惠券/品类推荐/文字锚点（待补充）
  const heightMap = {
    'HorizontalCoupon': '122px',
    'HorizontalAnchor': '72px', // 文字锚点
    'DrawSpin': '274px', // 转盘抽奖
  }
  const paddingMap = {
    'HorizontalItems': '27%',
    'RankingEntrance': '53%',
    'Video': '60%' // 视频组件 先给个固定值 看后续能不能推动添加视频比例配置项才能做准确占位
  }
  if (['UP_COUNTDOWN', 'LEFT_COUNTDOWN'].includes(styleType)) {
    Object.assign(heightMap, {
      Countdown: styleType === 'UP_COUNTDOWN' ? '168px' : '68px'
    })
  }

  // 1. 高度固定组件
  if (heightMap[__comp_name]) {
    return getRenderAttrs({
      'min-height': heightMap[__comp_name]
    })
  }
  // 2. padding固定组件
  if (paddingMap[__comp_name]) {
    return getRenderAttrs({
      paddingBottom: paddingMap[__comp_name]
    })
  }
  // 3. 精细化判断占位
  // 针对图片组件、图片锚点组件
  if (['IMAGE_CAROUSEL_COMPONENT', 'IMAGE_COMPONENT', 'ANCHOR_COMPONENT', 'COUNTDOWN_COMPONENT'].includes(componentKey)) {
    const { height, width } = items?.[0]?.image || items?.[0]?.items?.[0]?.image || items?.[0]?.imgSrc || {}
    return getRenderAttrs({
      paddingBottom: ((height / width) * 100).toFixed(2) + '%'
    })
  } else if (componentKey === 'FLASH_SALE') {
    const { metaData = {} } = data?.props || {}
    const { topBannerImage = {}, isShowBanner } = metaData
    return getRenderAttrs({
      paddingBottom: 27 + Number((isShowBanner && topBannerImage.ratio ? `${1 / topBannerImage.ratio * 100}` : '')) + '%'
    })
  } else if (styleType === 'DAILYNEW_ITEMS') {
    // dailynew商品
    const { rows, countPerRow, isShowViewAll = false } =  data?.props?.metaData || {}
    const base = 96 + 12 + (isShowViewAll ? 72 : 0) + (56 * rows) // tab高度96 + padding值12 + viewmore按钮高度 + 商品的名称价格高度56 
    const rowHeight = (100 / countPerRow) * 1.3415 * rows // 商品图片的高度
    return getRenderAttrs({
      paddingBottom: `calc(${rowHeight}% + ${base}px)`
    })
  } else if (styleType === 'LEFT_PICTURE_ITEMS') {
    // 左图右商品 组件
    const { title, lineNum = 1 } =  data?.props?.metaData || {}
    const titleHeight = !!title ? '76.8' : 0
    const rowPercent = lineNum == 1 ? '26.6' : '47.4'
    // 左图右商品
    return getRenderAttrs({
      paddingBottom: `calc(${rowPercent}% + ${titleHeight}px)`,
    })
  } else if (componentKey === 'SURVEY_COMPONENT') {
    // 意见收集组件
    const { isAllowMultiCommit = false } =  data?.props?.metaData || {}
    const isHasCommit = data?.survey || false // 其实获取不到 用户是否已经提交过问券
    if (isHasCommit) {
      return getRenderAttrs({
        'min-height': (isAllowMultiCommit ? 309 : 235) + 'px',
      })
    } else {
      const agree = 182 // 提交按钮 + 协议高度
      const quesMap = {
        '1': 126,    // 问题1 
        '2': 143.19,  // 问题2
        '3': 86,  // 问题3 单个 1.44 最大 12.1333
        '4': 86,  // 问题4 单个 1.44 最大 12.1333
        '5': 86, // 问题5
        '6': 86, // 问题6
        '7': 135.19,  // 问题7
      }
      const number = (items || []).reduce((prev, cur) => {
        const base = quesMap?.[String(cur?.quesType)]
        let other = 0
        if (cur?.quesType === 3 || cur?.quesType === 4) {
          other = 56 * Math.ceil(((cur?.quesOption || []).length || 0) / 3)
        } else if (cur?.quesType === 5 || cur?.quesType === 6) {
          other = 360.66 * Math.ceil(((cur?.quesOption || []).length || 0) / 4)
        }
        return base + other + prev
      }, 0)
      return getRenderAttrs({
        'min-height': agree + number + 'px'
      })
    }
  } else if (componentKey === 'PK_COMPONENT'){
    const items = data?.props?.items || []
    const maxImgRatio = Math.min(items[0]?.image?.ratio, items[1]?.image?.ratio)
    const ratio = ((1 / maxImgRatio) * 100) || 0
    let otherRatio = 8
    return getRenderAttrs({
      paddingBottom: otherRatio + Number(ratio) + '%'
    })
  } else if (componentKey === 'SHEIN_X_VOTE'){
    // 设计师投票组件
    const { layoutType = 'tile' } =  data?.props?.metaData || {}
    const statusHeight = 48
    const sortHeight = 98
    const designerListHeight = layoutType === 'tile' ? 1825 : 1005
    const totalHeight = statusHeight + sortHeight + designerListHeight
    return getRenderAttrs({
      'min-height': totalHeight + 'px'
    })
  } else if (styleType === 'NEWS_SECTION'){
    // 新闻板块组件
    const { items, metaData } =  data?.props || {}
    const bottomSpace = metaData?.disableBottomSpacing ? 0 : 16
    const rows = Math.ceil(items.length / 2)
    const totalHeight = rows * 170 + (rows - 1) * 10 + bottomSpace
    return getRenderAttrs({
      'min-height': totalHeight + 'px'
    })
  }
  return getRenderAttrs({})
}

export async function getServerTime() {
  try {
    const data = await schttp({ url: '/api/ccc/serverTime/get' })
    return data?.time || null
  } catch (error) {
    return null
  }
}

export const dayOfWeekInBeijing = (date) => {
  // 获取当前日期的世界标准时间
  const now = new Date(date)
  const utcTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
    now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

  // 计算时差，北京时区是UTC+8
  const ONE_HOUR = 60 * 60 * 1000
  const beijingTimestamp = utcTimestamp + ONE_HOUR * 8

  // 将时间戳转换为日期对象，再获取星期几
  const beijingDate = new Date(beijingTimestamp)
  return beijingDate.getDay()
}

// 获取当前元素相对document的坐标
export const getElementCoords = elem => {
  if (!elem) {
    return { top: 0, left: 0 }
  }
  try {
    const box = elem.getBoundingClientRect()
    const body = document.body
    const docEl = document.documentElement

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

    const clientTop = docEl.clientTop || body.clientTop || 0
    const clientLeft = docEl.clientLeft || body.clientLeft || 0

    const top = box.top + scrollTop - clientTop
    const left = box.left + scrollLeft - clientLeft

    return { top: Math.round(top), left: Math.round(left) }
  } catch (e) {
    console.error(e)
    return { top: 0, left: 0 }
  }
}

// HACK 重制浏览器滚动轴位置，修复页面刷新时滚动轴固定在底部，频繁触发加载
export const resetScrollbarPositionHACK = () => {
  try {
    const body = document.documentElement || document.body
    const scrollTop = body.scrollTop
    body.scrollTo({ top: scrollTop - 1 })
    body.scrollTo({ top: scrollTop })
  } catch (e) {
    console.error(e)
    return { top: 0, left: 0 }
  }
}

const  generateRandomNumber = function(){
  const currentTime = new Date().getTime() // 获取当前时间的毫秒数
  const random = Math.floor(Math.random() * 1000) // 生成0到999之间的随机数
  return `${currentTime}${random}` // 将当前时间与随机数相加作为最终结果
}
// cccx 多组件通信
// 如果一定需要用到
// eventName：组件名称-事件名称
// 支持emit同一eventName，
export const cccxEventBus = {
  event: {},
  once(eventName, payload) {
    if (typeof payload !== 'function') return
    this.event[eventName] = [{ key: generateRandomNumber(), fn: payload }]
  },
  off(eventName, eventNameId) {
    if(eventNameId) {
      this.event[eventName].splice(this.event[eventName].findIndex(v => v.key === eventNameId), 1)
    } else {
      delete this.event[eventName]
    }
  },
  on(eventName, payload, eventNameId) {
    if (typeof payload !== 'function') return
    if (this.event[eventName]) {
      if(eventNameId) {
        this.event[eventName].push({ key: eventNameId, fn: payload })
      } else {
        this.event[eventName].push({ key: generateRandomNumber(), fn: payload })
      }
    } else {
      if(eventNameId) {
        this.event[eventName] = [{ key: eventNameId, fn: payload }]
      } else {
        this.event[eventName] = [{ key: generateRandomNumber(), fn: payload }]
      }
    }
  },
  emit(eventName, ...arg) {
    this.event?.[eventName]?.forEach(({ fn: payload }) => payload(...arg))
  },
}

/**
 * 添加列表公共参数
 * @param {Object} options 
 * @param {Object} options.sceneData 场景数据
 * @returns 
 */
export function getListCommonParams({ sceneData, propData, index }) {
  if (typeof window === 'undefined') return {}
  const componentId = getQueryString({ key: 'componentId' })
  const componentAdp = getQueryString({ key: 'componentAdp' })
  
  const result = {
    scene: sceneData?.pageFrom,
    componentId: propData?.componentId,
    styleType: propData?.styleType,
    channelName: sceneData?.tabName,
    blockKey: propData.blockKey,
  }

  // 专题页面组件，根据 componentId 配置置顶 adp
  if (sceneData?.pageFrom === 'campaign' && +componentId === propData?.componentId && componentAdp && index === 0) {
    result.adp = componentAdp
  }

  return result
}

const REC_ATOMIC_PAGE_KEY_ARRAY = [
  'campaign',
  'storeHomePage',
  'RealClassEmptyRecommend',
  'SelectClassEmptyRecommend',
  'OtherListEmptyRecommend',
  'OtherListFilteredRecommend',
  'ConfirmDeliveryRecommend',
  'CancelPartialOrderRecommend',
  'UnpaidorderCancelRecommend',
  'RepurchaseRecommend',
  'collectionBoardsRecommend',
  'collectionBoardsEmptyRecommend',
  'orderListRecommend',
  'couponRecommend',
  'orderCommentRecommend',
  'returnSuccessfulRecommend',
  'refundDetailRecommend',
]
export const REC_ATOMIC_PAGE_KEY_MAP = {
  findSimilarRecommend: 'page_find_similar',
  FindSimilarRecommend_new: 'page_find_similar',
  findSimilar: 'page_find_similar',
  pageFindSimilar: 'page_find_similar',
  LogisticTrackRecommend: 'page_order_track',
  LogisticTrackRecommend_new: 'page_order_track',
  shoppingCartRecommend: 'page_cart',
  OrderDetailRecommend: 'page_order_detail',
  PaymentSuccessfulRecommend: 'page_pay_success',
  CollectionRecommend: 'page_collection',
  CollectionEmptyRecommend: 'page_collection',
  SearchRecommend: 'page_search',
  SearchEmptyRecommend: 'page_search',
  SelectClassFilteredRecommend: 'page_select_class',
  RealClassFilteredRecommend: 'page_real_class',
  PersonalCenterRecommend: 'page_me',
  ...REC_ATOMIC_PAGE_KEY_ARRAY.reduce((pre, cur) => { 
    pre[cur] = 'recommend_page' 
    return pre
  }, {})
}

/**
 * @description ccc组件统一跳转
 * @param {string} url
 * @returns {void}
 */
export const linkTo = (url, openTarget = '_self') => {
  if (!url) return
  if (openTarget === '_blank') {
    window.open(url, openTarget)
    return
  }
  window.location.href = url
}



/**
 * 获取justWatch样式配置
 * @param {String} componentId 
 * @returns 
 */
export function getJustWatchConfig(originalUrl, curComponentId) {
  const justWatchStyleConfig = {
    isAdpComp: false,
    adp: 0,
  }

  if (!originalUrl) {
    return justWatchStyleConfig
  }

  try {
    const searchParams = new URLSearchParams(originalUrl?.split('?')[1])
    const componentId = searchParams.get('componentId')
    const componentAdp = searchParams.get('componentAdp') // null

    // 判断是否命中当前组件
    justWatchStyleConfig.isAdpComp = componentId && componentId == curComponentId 
    // 获取adp
    const adpList = componentAdp || '0'
    justWatchStyleConfig.adp = adpList?.split(',')[0]

  }catch(e) {
    console.log(e)
  }
  return justWatchStyleConfig
}

/**
 * 通过选品id，获取选品链接
 *
 * @export
 * @param {string} [sc_id='']
 * @return {Promise}
 */
export function getSelectInfoByScId(sc_id = '') {
  return new Promise((resolve, reject) => {
    schttp({ 
      url: '/api/productInfo/selectInfoByScId/get',
      params: {
        sc_id
      }
    }).then(data => {
      if (data && data.info && data.info.scInfo && data.info.scInfo.length) {
        let { sc_url_id, select_name, select_type_name, sc_url } = data.info.scInfo[0]
        if (sc_url) {
          resolve(sc_url)
        } else if (sc_url_id && select_name && select_type_name) {
          const url = '/' + select_type_name + '/' + select_name + '-sc-' + sc_url_id + '.html'
          resolve(url)
        } else {
          reject(data)
        }
      } else {
        reject(data)
      }
    })
  })
}
/**
 * 手动上报客户端事件，用于性能统计
 * @param {Object} param
 * @param {String} param.name 事件名称
 * @param {Number} param.startTime 开始时间，单位毫秒
 *   
 */
export const reportClientEvent = ({ name, startTime }) => {
  try {
    const endTime = Date.now()
    const data = {
      // resource, // todo 区分ssr
      data: [
        {
          key_path: name,
          values: {
            num: endTime - startTime,
          },
        },
      ],
    }
    window.TPM?.run({
      marketing: 'ClientAnalytics',
      method: '_defineTrack',
      params: {
        data,
        options: {
          random: 1,
          immediate: true,
        },
      },
    })
  } catch(e) {
    console.error(e)
  }
}
