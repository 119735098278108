export default {
  refreshAnalysisParams({ params }) {
    if(typeof window != 'undefined') {
      window.PageGroupOverview = '专题页'
      window.PageGroup = '专题页'
      // 页面信息
      window.SaPageInfo = {
        page_id: 41,
        page_name: 'page_activity_factory',
        page_param: Object.assign({}, params),
        start_time: Date.now()
      }
      // this.exposeGoodIdList = []
    }
  }
}
