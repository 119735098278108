<template>
  <div
    :style="bgStyle"
    :data-background-image="backgroundImgSrc"
  >
    <AppCcc
      class="ccc-campaigns"
      :class="maxWidthClass"
      :content="content"
      :context="context"
      :scene-data="sceneData"
      :cate-links="cateLinks"
      :container-width="1920"
    />
  </div>
</template>

<script>
import AppCcc from 'public/src/pages/components/ccc/Index.vue'
import { getQueryString, parseQueryString } from '@shein/common-function'
import campaignsAnalysis from './analysis'
import { abtservice } from 'public/src/services/abt/index.js'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import SILog from 'public/src/pages/common/monitor/index'

export default {
  name: 'CccCampaigns',
  components: {
    AppCcc,
  },
  props: {
    context: {
      type: Object,
      default: () => ({}),
    },
  },
  provide() {
    return {
      cutImg: this.cutImg,
    }
  },
  data() {
    return {
      aod_id: '-',
    }
  },
  computed: {
    bgStyle() {
      const {
        color = '',
        fillType = '1',
        backgroundSize = '3',
        backgroundImgSrc = {},
      } = JSON.parse(this.context?.content?.extend)
      const fillTypeMap = {
        '1': 'no-repeat', // 上传图片长度
        '2': 'repeat', // 实际页面长度
      }
      const mapSize = {
        '1': 'auto',
        '2': 'cover',
        '3': 'contain',
        '4': '100%',
      }

      return {
        backgroundColor: color,
        backgroundSize: mapSize[backgroundSize],
        backgroundRepeat: fillTypeMap[fillType],
        backgroundImage: backgroundImgSrc?.src ? `url(${this.cutImg(backgroundImgSrc.src)})` : 'none',
      }
    },
    backgroundImgSrc() {
      const {
        backgroundImgSrc = {},
      } = JSON.parse(this.context?.content?.extend)
      return this.cutImg(backgroundImgSrc?.src) || ''
    },
    maxWidthClass() {
      const { pageWidth = '' } = JSON.parse(this.context?.content?.extend)
      const widthMap = {
        '1': '', // 自适应
        '2': 'max-width-1200', // 1200px
        '3': 'max-width-1800', // 1800px
      }
      return widthMap[pageWidth] || ''
    },
    content() {
      return this.context?.content?.content
    },
    sceneData() {
      return {
        pageType: this.context?.content?.pageType || '',
        id: this.context?.content?.id || '',
        pageFrom: 'campaign',
        sceneName: this.context?.content?.pageType || '',
        identity: this.context?.content?.identity || '',
        activityId: this.context?.content?.id || '',
        activityNameEn: this.context?.content?.identity || '',
      }
    },
    cateLinks() {
      return this.context?.content?.cateLinks
    },
    resourceSDK () {
      const { RESOURCE_SDK = {} } = this.context || {}
      return RESOURCE_SDK
    }
  },
  beforeMount() {
    this.getIciAodId()
    this.handlePageViewParams()
    // 触发pv
    sa('set', 'setPageData', window.SaPageInfo)
    sa('send', 'pageEnter', {})

    window.onbeforeunload = function () {
      sa('send', 'pageLeave', { end_time: new Date().getTime() })
    }
  },
  mounted() {
    this.setFspOptions()
  },
  methods: {
    setFspOptions() {
      const self = this
      window.fspSDK.updateOptions({
        reporterHandler({ name, value, payload = {} } = {}) {
          if (!name || !value || window.isClientBot) {
            return
          }
          const resource = payload.resource || ''
          if (value > 3000) {
            try {
              const fileds = SILog.getFields()
              SILog.SIMetric.metricCount({
                metric_name: 'activity_long_fsp_total',
                tags: {
                  site: window?.gbCommonInfo?.SiteUID || '',
                  identity: self.sceneData?.identity || '',
                  pathname: location.pathname,
                  os_name: fileds?.os_name || '',
                  device_model: fileds?.device_model || '',
                  fspValue: value,
                },
              }, {
                immediate: true,
              })
              // window.ErrorJs && window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
              //   appName: 'shein_w',
              //   activity_name: self?.sceneData?.identity,
              //   errorMark: 'Activity_Long_FSP',
              //   error_type: 'script_error',
              //   errorInfo: {
              //     identity: self?.sceneData?.identity,
              //     fspValue: value,
              //     pathname: location.pathname,
              //     href: location.href,
              //   },
              //   reportList: [
              //     {
              //       metricName: 'activity_long_fsp_total',
              //       site: gbCommonInfo?.SiteUID,
              //       identity: self?.sceneData?.identity,
              //       pathname: location.pathname,
              //     },
              //   ]
              // })
            } catch (error) {
              console.log('error', error)
            }
          }
          window.TPM?.run({
            marketing: 'ClientAnalytics',
            method: '_defineTrack',
            params: {
              data: {
                resource,
                data: [
                  {
                    key_path: name,
                    values: { num: value }
                  },
                ]
              },
              options: {
                random: 1,
                immediate: true,
              },
            }
          })
        }
      })
    },  
    handlePageViewParams() {
      // console.log(this.context)
      const { id, identity, abtParams = {} } = this.context?.content || {}
      const { isRomwe = false } = this.context || {}
      const poskey = isRomwe ? 'RMActAbt' : 'SMActAbt'
      const targetKey = Object.keys(abtParams).find(key => key.includes(poskey))
      // const test_branch = getQueryString({ key: 'test_branch' })
      // const branches = p.split('|')
      // const branch = test_branch
      //   ? test_branch
      //   : branches.find((b) => b.split(':')[0] === identity) || ''
      const { composeId: compose_id = '', item_id = '', scene_id = '', contentCarrierId = '-', templateId: template_id = '-', } = parseQueryString(location.search)

      let abtInfo = { sa: '' }
      if (abtParams[targetKey]?.posKey) {
        // eslint-disable-next-line @shein-aidc/abt/abt
        abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys: abtParams[targetKey]?.posKey, abtInfo: abtParams })
        console.log('abtInfo: ', abtInfo)
      }

      campaignsAnalysis.refreshAnalysisParams({
        params: {
          mod1_id: id,
          name_en: identity,
          pagefrom: getQueryString({ key: 'ici' }) || getQueryString({ key: 'productsource' }) || '',
          abtest: '-`-`-',
          crowd_id: '',
          new_flag: 1,
          compose_id,
          item_id,
          scene_id,
          auto_page: scene_id ? 1 : 0,
          content_id: contentCarrierId,
          template_id,
          // top_goods_id: '-',
          aod_id: this.aod_id,
          src_module: getQueryString({ key: 'src_module' }) || '',
          src_identifier: getQueryString({ key: 'src_identifier' }) || '',
          src_tab_page_id: getQueryString({ key: 'src_tab_page_id' }) || '',
        }
      })
    },
    getIciAodId() {
      const aod_id = new URLSearchParams(location.search).get('aod_id')
      this.aod_id = aod_id || '0'
      if (location.search.indexOf('ici') !== -1) {
        const iciSearchArr = decodeURIComponent(location.search).split('&')
        iciSearchArr.map((val) => {
          if (val && val.indexOf('ici') !== -1) {
            const iciStr = val.split('_')
            iciStr &&
              iciStr.map((v) => {
                if (v.indexOf('aod') > -1) {
                  this.aod_id = v.split('=')[1] || '0'
                }
              })
          }
        })
      }
    },
    cutImg (imgUrl, designWidth, exp) {
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false } = this.resourceSDK

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        exp,
      }

      return transfromAndCutImg(cutData)
    }
  },
}
</script>

<style lang="less">
.ccc-campaigns {
  margin: 0 auto;
  padding: 0 48px;
  // 页面两边固定留48px的间距, 宽度加96px
  &.max-width-1200 {
    max-width: 1296px;
  }
  &.max-width-1800 {
    max-width: 1896px;
  }
}
</style>
