/**
 * PromotionIcon 推全 ShowPromotionIcon
 */
export default {
  pageName: 'page_brand_zone',
  newPosKeys: [
    'PageBrandZone'
  ],
  posKeys: [],
}
