/**
 * @description 公共poskey 请公共放入此文件，页面级放在自己页面文件中
 * @returns { posKeys, newPosKeys }
 */

function getAppNewPoskey() {
  const { WEB_CLIENT = 'shein' } =
    typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

  const APP_NEW_POSKEY = [
    'HomepageWhatsapp',
    'UnderPrice',
    'VerifyCodeSwitch',
    'LowpriceUser',
    'CouponTimeFormatChange', // 优惠券时间格式
    'CouponbagUpdate',
    'SearchListCoupon',
    'SearchSceneCoupon',
    'PhoneAreaCodePopop',
    'recSwitch', // 推荐poskey开关
    'SearchSceneCoupon',
    'RelatedSearchNew', // 搜索定位控制
    'SuggestUI',
    'DefaultWordTag', // 底纹词打标签
    'SearchSuggestNew', // 联想词
    'PCAlltabCategory', // 顶部样式控制 
    'MeClubTrailSwitch',
    'storewishlist',
    'SearchDefaultNew',
    'searchwordstyle', // HK标签
    'SearchHotGD',
    'SearchHotNew',
    'PcCartPrefetch',
    'detailcountdown',
    'PageMeNews',
    'HomePopup', // 全局弹窗
    'AccountManager', // 多账号管理
    'storeiconchange', // 全局店铺新旧icon
    'newpresearch',
    'newpresearchlenovo',
    'newpresearchicon',
    'newsearch', // 全局搜索框样式优化
    'Reviewchange',
    'subrating',
    'PerformanceLogNodeTestFetch',   
  ]
  const SHEIN_NEW_POSKEYS = []
  const ROMWE_NEW_POSEKYS = []

  if (WEB_CLIENT === 'shein') {
    APP_NEW_POSKEY.push(...SHEIN_NEW_POSKEYS)
  } else {
    APP_NEW_POSKEY.push(...ROMWE_NEW_POSEKYS)
  }

  return APP_NEW_POSKEY
}

function getAppPoskey() {
  const { WEB_CLIENT = 'shein' } =
    typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

  const campaignPoskey = new Array(10)
    .fill('ActAbt')
    .map((v, i) => `${v}${i + 1}`)

  // 公用posKey
  const APP_POSKEY = [
    'Searchfilter',
    'SearchSuggestwordFeedback',
    // 'SearchCategoryWord', 推全type=B
    // 'CccListSideBanner',
    // 'CccListTopBanner',
    // 'CccListBottombanner',
    // 'CccListFlow', // 已推全-写死值 ShowOrderApplication
    'CccxPublicBanner',
    'CccAppIcon', // 旧悬浮组件使用，若旧ccc悬浮组件下线后即可删除
    // 'TopRotation',
    'CccKidsCategory',
    'CccHomeCategory',
    'CccPlussizeCategory',
    'Aod',
    // 'JyRisk',
    // 'JyRiskReg',
    // 'JyRiskZTReg',
    'Similaritems',
    'Aftersimilar',
    'Reviewsubmit',
    // 'pop',
    'Orderdetail',
    'OrderTrack',
    'ChangePassword',
    // 印度COD订单退货退款到账户
    'CODwithdraw',
    // 设备指纹
    // 'SMDeviceId',
    //基码
    'Bodysize',
    // 'GMSPromotion',
    // 快速注册弹窗
    'FastRegisterPopup',
    // 'FlashsaleAddSortFilter', // 废弃-闪购列表增加排序筛选
    'ThirtyLogin',
    'SPcSignPrefer',
    'newPrivacy',

    // emarsys 数据上报控制
    'ProductDetailYouMayAlsoLike',
    // 'ProductDetailCustomersAlsoViewed',
    'ProductDetailCustomersAlsoViewedList',
    // 新旧品牌系列ui方案
    'CookieResult',

    // 是否接入自有尺码推荐
    'Selfsizerecommended',
    // 'ShowGetTheLook',

    // 专题
    'ACTrandom',
    'ActDefault',
    'ActHot',
    'ActNew',
    'ActElement',
    'ActAbt',
    'ActReview',
    'ActFavorite',
    // 多主体隐私政策查询
    'SilentloginPrivacy',
    // 履约异常
    'SpecialOutgoingScene',
    // 快速注册弹窗
    'Quickregclosed',
    // 登陆注册切站提示
    'LoginSwtichsite',
    'phoneRegisterLogin',
    'LoginMerge',
    'SearchCard',
    'AddShowGroup',

    'WishItemSize',
    ...campaignPoskey,
    'sheinclubprice',
    'FlashShowedCrowed',
    'Movepoints',
    'CccxPublicBanner',
    'CCCxTopbanner',
    'RecentlyViewNotlogged',
    'GoogleOneTapSignIn',
    'Cartshowobmtitle',
    'Showplatformtitle',
    'TradeQuickship',
    'Cartnoteligibleforcoupons',
    'Discountlistshowlimitexceededtip',
    'AllListQuickShip'
  ]

  const SHEIN_POSKEYS = ['CccMenCategory', 'OpenFreeTrial']
  const ROMWE_POSEKYS = ['CccGirlsCategory', 'CccGuysCategory']

  return WEB_CLIENT === 'shein'
    ? APP_POSKEY.concat(SHEIN_POSKEYS)
    : APP_POSKEY.concat(ROMWE_POSEKYS)
}

/* 头部组件（公共） */
const HEADER_POSKEY = {
  newPosKeys: [
    'Cartshowcoupon',
    'Cartentranceinfo',
  ],
}

/* CCCX推荐（公共） */
const RECOMMEND_POSKEY = {
  newPosKeys: [
    'pclistnewCard', 
    'listdiscountLabel', 
    'DetailStarReview', 
    'detailgoodsCard', 
    'listflashSale', 
    'listpriceTag', 
    'detailKeyAttribute', 
    'pcwordSize', 
    'FlashSaleCountDown', 
  ],
  posKeys: [],
}
export default {
  posKeys: getAppPoskey(),
  newPosKeys: [
    ...getAppNewPoskey(),
    ...HEADER_POSKEY.newPosKeys,
    ...RECOMMEND_POSKEY.newPosKeys,
  ]
}
