/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import { getCookie } from '@shein/common-function'

import UserKey from './UserKey'

import Country from './UserKeys/Country'
import Location from './UserKeys/Location'
import Language from './UserKeys/Language'
import MemberId from './UserKeys/MemberId'
import UserEmail from './UserKeys/UserEmail'
import Currency from './UserKeys/Currency'
import UserInfo from './UserKeys/UserInfo'
import UgidInfo from './UserKeys/Ugid'
import WebPush from './UserKeys/WebPush'


UserKey.classes = [
  Country,
  Location,
  Language,
  MemberId,
  UserEmail,
  Currency,
  UserInfo,
  UgidInfo,
  WebPush,
  
  // UserKey
]

class UserInfoManager {
  static get({ key, options, actionType = '' }) {
    UserInfoManager.getActionLists.add(actionType)

    return UserKey.for({ key }).get(options)
  }

  static set({ key, value, options = {}, actionType = '' }) {
    UserInfoManager.setActionLists.add(actionType)

    return UserKey.for({ key }).set(value, options)
  }

  static getSync({ key, options, actionType = '' }) {
    UserInfoManager.getActionLists.add(actionType)

    return UserKey.for({ key }).getSync(options)
  }

  /**
   * 是否登录
   */
  static isLogin() {
    return !!getCookie('memberId')
  }

  /**
   * 语言切换
   */
  static switchLanguge() {

  }

  /**
   * 清除对应 UserKey 的 localstorage / sessionstorage 缓存
   */

  static clearStorage({ key }) {
    UserKey.for({ key }).clearStorage()
  }

  /**
   * 清除所有 UserKey 的 localstorage / sessionstorage 缓存
   */
  static clearAllStorage() {
    ['Country', 'language', 'memberId', 'userinfo_email', 'pwa_user_email', 'ugid'].forEach(key => {
      UserInfoManager.clearStorage({ key })
    })
  }

}

UserInfoManager.getActionLists = new Set()
UserInfoManager.setActionLists = new Set()

export default UserInfoManager
