export default {
  pageName: 'page_live_chat',
  newPosKeys: [
    'MultiPushList',
    'UnrecongizedCard',
    'FuzzyMatchCard',
    'ConfirmationNoCard',
    'CsOrderlistCard',
    'CsOrderdetailCard',
    'CSchat',
    'ExchangeFunction',
    'BotStrategy',
    'inviteReview',
    'Rate',
    'switchOrder',
    'allshiptimeshow',
    'orderQuickshipStrong',
    'contactagent',
    'returnUrge',
    'EditOrderAddress',
    'servicechannellimit'
  ],
  posKeys: [
    'Orderdetail',
    'Multiround',
    'Slq',
    'IsCanRefund',
    'giftOrderDetail',
    'IsInfoFront',
    'OrderlistQueryRelatedAccounts'
  ],
}
