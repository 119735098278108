/**
 * PromotionIcon 推全 ShowPromotionIcon
 */
export default {
  pageName: 'page_cart',
  newPosKeys: [
    'componentswitch'
  ],
  posKeys: [
    'CartYouMayAlsoLike',
    'EmptyCartYouMayAlsoLike',
    'Add',
    'FreeShipping',
    'ReselectSimilar',
    // 'PromotionIcon', // 购物车限时活动标签
  ],
}
