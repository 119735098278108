import UserKey from '../UserKey'

const UGID_KEY = 'ugid'
const TIME_OUT = 5000

function timeoutPromise(promise, time) {
  return Promise.race([promise, new Promise((resolve) => setTimeout(() => resolve(null), time))])
}

let promise = null
let ugidData = null
let isSetData = false

class Ugid extends UserKey {
  static canHandle(key) {
    return key === UGID_KEY
  }

  /**
   * @description 异步get调用
   * */ 
  async get () {
    if (isSetData) {
      return this.getSync()
    }

    if (!promise) {
      promise = timeoutPromise(this.getUgidApi(), TIME_OUT)
    }
    const res = await promise
    this.set(res)
    return res
  }

  // 同步获取，不保证一定有值
  getSync() {
    return ugidData
  }

  set(value = '') {
    isSetData = true
    ugidData = value
  }

  clearStorage() {
    isSetData = false
    ugidData = null
    promise = null
  }
}

export default Ugid
