// 专题abt配置

export default {
  pageName: 'page_activity_factory',
  newPosKeys: [
    'recSwitch',   
    'HomeEstimatedPrice',
    'FSEstimatedPrice',
    'EstimatedPrice', 
  ],
  posKeys: [],
}
