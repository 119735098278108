let IS_DEBUG = null
const isDebug = () => {
  if (IS_DEBUG !== null || typeof window === 'undefined') {
    return IS_DEBUG
  }

  const url = new URL(location.href)
  const value = url.searchParams.get('__best_shein_debug_prefetch__')
  const sessionKey = '__is_debug__'

  if (value) {
    // qs 有 debug 参数, 直接用参数判断, 并且更新 session
    window.sessionStorage.setItem(sessionKey, value)
    IS_DEBUG = value === 'true'
  } else {
    // 没有 debug 参数, 尝试从 session 里获取
    const sessionValue = window.sessionStorage.getItem(sessionKey)
    IS_DEBUG = sessionValue === 'true'
  }

  return IS_DEBUG
}

const log = (...args) => {
  if (!isDebug()) return
  const color = 'background: #222; color: #bada55'
  console.log(`%c [prefetch info]`, color, ...args)
}

const ErrorThrow = (msg) => {
  throw new Error(`[prefetchResource] ${msg}`)
}

const throttles = (limit = 1) => {
  if (limit < 1) ErrorThrow('throttles: limit must be greater than 0')
	
  let lowPriorityQueue = [], hightPriorityQueue = [], waitProcessTaskNum = 0, inProcess = 0
  
  function toAdd(fn, isHigh) {
    if (fn.__isRun) {
      // 1.已经执行过的任务不在执行, 2.已经执行，但是优先级前后不一致，需要清理lowPriorityQueue的任务
      if (isHigh) {
        let idx = lowPriorityQueue.indexOf(fn)
        if (!!~idx) lowPriorityQueue.splice(idx, 1).length && waitProcessTaskNum--
      } else return
    }
    fn.__isRun = 1;
    (isHigh ? hightPriorityQueue : lowPriorityQueue).push(fn)
    waitProcessTaskNum++ || run()
  }

  function isDone() {
    // release ....
    inProcess--
    run()
  }

  function run() {
    if (inProcess < limit && waitProcessTaskNum > 0) {
      (hightPriorityQueue.shift() || lowPriorityQueue.shift())()
      waitProcessTaskNum--
      inProcess++
    }
  }

  return [toAdd, isDone]
}

export{
  log,
  ErrorThrow,
  throttles
}
