export default [
  {
    pageName: 'page_order_list',
    posKeys: ['Orderlist'],
  },
  {
    pageName: 'page_order_detail',
    newPosKeys: [],
    posKeys: [
      'OrderRecommendationsForYou',
      'SelfExchange',
      'Subscribe',
    ],
  },
]
