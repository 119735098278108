/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import { getCookie } from '@shein/common-function'
import UserKey from '../UserKey'

// 本地存储key
const COOKIE_KEY = 'memberId'
class MemberId extends UserKey {
  static canHandle(key) {
    return key === COOKIE_KEY
  }

  /**
   * 同步获取 cookie 中的 memberId
   */
  get () {
    return getCookie(this.key) || ''
  }
}

export default MemberId
