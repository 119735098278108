/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import schttp from 'public/src/services/schttp'
import apiReport from 'public/src/pages/common/apiReport/index.js'
import { getCookie, getLocalStorage, setLocalStorage, removeLocalStorage } from '@shein/common-function'
import UserKey from '../UserKey'

// 存储key
const STORAGE_KEY = 'userinfo_email'

// 客户端缓存有效期1小时
const CLIENT_STORAGE_EXPIRE = 60 * 60 * 1000

class UserEmail extends UserKey {
  static canHandle(key) {
    return key === STORAGE_KEY
  }

  getStorageUserinfoEmail () {
    let storageUserinfoEmail = ''
    try {
      let CACHE_KEY = this.initStoreCacheKey()
      storageUserinfoEmail = getLocalStorage(CACHE_KEY) || ''
    } catch (e) {
      console.log('UserInfoManage Get UserEmail Error', e)
      storageUserinfoEmail = ''
    }
    return storageUserinfoEmail
  }

  clearStorageUserinfoEmail () {
    try {
      let allKeys = Object.keys(localStorage || {})
      // 筛选包含STORAGE_KEY的缓存
      allKeys = allKeys.filter(key => key.includes(STORAGE_KEY))
      allKeys.length && allKeys.forEach(key => removeLocalStorage(key))
    } catch (e) {
      console.log('clear localStorage error', e)
    }
  }

  initStoreCacheKey () {
    return `${this.getMemberId()}_${STORAGE_KEY}`
  }

  // 异步获取user_email
  async get () {
    /**
     * 如果有登录态 且 客户端缓存中user_email有值, 则直接返回客户端缓存的user_email
     * 如果有登录态 且 客户端缓存中user_mail没有值, 从服务端获取user_email, 并存一次客户端缓存
     * 其它场景返回cookie兜底, 如果cookie无值, 就返回空
     */ 

    // 如果没有登录清除user_email缓存信息
    if (!this.isLogin()) {
      this.clearStorageUserinfoEmail()
      return ''
    }

    let storageUserinfoEmail = this.getStorageUserinfoEmail()
    if (storageUserinfoEmail) return storageUserinfoEmail

    let { data = '', code = '' } = await this.getServiceUserinfoEmail()
    if (code === '0') {
      // 设置之前清除一次缓存
      this.clearStorageUserinfoEmail()
      if (!!data) {
        let CACHE_KEY = this.initStoreCacheKey()
        setLocalStorage({
          key: CACHE_KEY,
          value: data,
          expire: CLIENT_STORAGE_EXPIRE
        })
      }
      return data || ''
    } else {
      apiReport.report({
        apiPath: 'get/user-email/error',
        errorSubType: 'get_user_email',
        errorType: 'get_user_email_error',
        error_scene: 'get_user_email_default'
      })
      return getCookie(STORAGE_KEY) || ''
    }
  }

  // 同步获取user_email: 特殊场景使用
  getSync () {
    /**
     * 如果有登录态 且 客户端缓存中user_email有值, 则直接返回客户端缓存的user_email
     * 其它场景返回cookie兜底, 如果cookie无值, 就返回空
     */ 

    // 如果没有登录清除user_email缓存信息
    if (!this.isLogin()) {
      this.clearStorageUserinfoEmail()
      return ''
    }
    
    let storageUserinfoEmail = this.getStorageUserinfoEmail()
    if (storageUserinfoEmail) return storageUserinfoEmail

    return getCookie(STORAGE_KEY) || ''
  }

  // 从服务端获取user_email
  async getServiceUserinfoEmail () {
    let res = await schttp({
      url: '/api/common/userInfoManager/get',
      params: {
        key: STORAGE_KEY,
        actionType: 'getServiceUserInfoEmail'
      }
    })
    return res || {}
  }
}

export default UserEmail
