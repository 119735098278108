import { PrefetchResource } from './core'

const prefetchResource = new PrefetchResource({ 
  manifest: typeof window !== 'undefined' && window?.resourceManiFest || {}, 
  staticDomain: typeof window !== 'undefined' && window?.gbCommonInfo?.PUBLIC_CDN
})

typeof window !== 'undefined' && (window.prefetchResource = prefetchResource)

export {
  prefetchResource
}
