import { setCookie, getCookie, removeCookie } from '@shein/common-function'
import UserKey from '../UserKey'

const WEBPUSH_KEY = 'webpushcookie'

class WebPush extends UserKey {
  static canHandle(key) {
    return key === WEBPUSH_KEY
  }

  /**
   * @description 异步get调用
   * */ 
  async get () {
    return getCookie(WEBPUSH_KEY)
  }

  set(value = '') {
    setCookie({
      key: WEBPUSH_KEY, 
      value: `agree:${value}`,
      end: 3600 * 24 * 365
    })
  }

  clearStorage() {
    removeCookie({
      key: WEBPUSH_KEY
    })
  }
}

export default WebPush
