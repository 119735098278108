/*
 * @Author: shuo 
 * @Date: 2019-06-25 22:57:22 
 * @Last Modified by: shuo
 * @Last Modified time: 2019-10-30 15:59:11
 */
export default class publisher {
    
    constructor({ controlCenter = null } = {}) {
        this.controlCenter = controlCenter
    }

    notify({ target = null, daId = null, sendType = 'event', bindData = null, extraData = {} } = {}) {
        if (!daId) return
        this.controlCenter.publish({ target, daId, sendType, extraData, bindData })
    }
}
