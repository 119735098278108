import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'


// 是否是付费会员
let primeInfo = null
let requestBegin = false
async function getPrimeInfo ({ isAbtFilter }) {
  if (!isLogin()) return {}
  if (primeInfo) return primeInfo
  if (requestBegin) {
    // TODO 优化
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        resolve(await getPrimeInfo({ isAbtFilter }))
      }, 100)
    })
  }
  requestBegin = true
  const res = await schttp({ 
    url: '/api/others/postShowPrime/get',
    method: 'POST',
    data: { isAbtFilter }
  })
  if (res?.code == '0') {
    primeInfo = res
  }
  requestBegin = false
  return primeInfo
}

// 是否是VIP会员
let vipInfo = null
let requestVipBegin = false
async function getVipInfo () {
  if (!isLogin()) return {}
  if (vipInfo) return vipInfo
  if (requestVipBegin) {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        resolve(await getVipInfo())
      }, 100)
    })
  }
  requestVipBegin = true
  let res = await schttp({
    url: `/api/user/shein_romwe_vip/getUserLevel/get`
  })
  
  let info = res?.info || {}
  let open = info?.open
  let level = info?.level
  let site_from = info?.site_from
  let isOldEsVip = info?.site_level_config?.levels?.length <= 2 ? 1 : 0
  let showVipFlag = !!level && open == 1

  vipInfo = {
    level,
    site_from,
    isOldEsVip,
    showVipFlag
  }

  requestVipBegin = false
  return vipInfo
}

export {
  getPrimeInfo,
  getVipInfo
}
