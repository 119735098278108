/*
 * @Author: shuo 
 * @Date: 2019-06-25 22:58:22 
 * @Last Modified by: shuo
 * @Last Modified time: 2020-03-26 22:19:34
 */

import analysis from './analysis' 
import defaultReport from './analysis/report'

export default class subscriber {

  constructor({ controlCenter = null } = {}) {
    this.controlCenter = controlCenter
    this.analysis = analysis
  }

  subscribe({ modulecode = null } = {}) {
    if (!modulecode) return

    this.analysis.require({ modulecode }).then(module => {
      if (!module || !module.default) return
      let mapList = module.default
      Object.entries(mapList).forEach((obser) => {
        // 没有声明handler，默认直接上报 sa ga
        let { sa: source_sa, ga: source_ga, sendType } = obser[1]
        const sa = source_sa ? JSON.parse(JSON.stringify(source_sa)) : undefined
        const ga = source_ga ? JSON.parse(JSON.stringify(source_ga)) : undefined
        let handler = !obser[1].handler
          ? defaultReport.report.bind(defaultReport, { sa, ga })
          : obser[1].handler.bind(module.default, { sa, ga, report: defaultReport.report.bind(defaultReport) })

        this.controlCenter.subscribe({
          handler,
          daId: obser[0],
          sendType
        })
      })
    })
  }
}
