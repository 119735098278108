/*
 * @Author: shuo 
 * @Date: 2019-06-25 23:10:22 
 * @Last Modified by: shuo
 * @Last Modified time: 2020-04-03 11:57:33
 */
export default class controleCenter {
  /*
       subscriber: {
           eventid: {
               sendType: ['event'],
               handler: fun()
           },
           eventid: {
               sendType: ['event', 'user'],
               handler: fun()
           },
           eventid: {
               sendType: ['event'],
               handler: fun()
           },
           eventid: {
               sendType: ['event'],
               handler: fun()
           }
       }
   */

  constructor() {
    this.subscriber = {}
    this.unSendEvent = {}
    this.has = Object.prototype.hasOwnProperty
  }

  publish({ target = null, daId = null, bindData = null, sendType = 'event', extraData = {} } = {}) {
    if (!daId) return
    // get daId
    if (!this.has.call(this.subscriber, daId)) {
      this.pushEventInQueue({ target, daId, sendType, extraData, bindData })
      return
    }
    // publish sendType value equal declare value
    if (this.subscriber[daId]['sendType'].includes(sendType)) this.subscriber[daId]['handler']({ target, extraData, bindData })
  }

  subscribe({ daId = null, handler = null, sendType = false } = {}) {
    if (!daId || !handler) return
    // 已经注册过了 避免重复注册
    if (!!this.has.call(this.subscriber, daId)) return
    this.subscriber[daId] = {
      sendType,
      handler
    }
    this.executeEventInQueue({ daId, handler, sendType })
  }

  pushEventInQueue({ target = null, daId = null, sendType = 'event', bindData = null, extraData = {} } = {}) {
    if (!this.has.call(this.unSendEvent, daId)) this.unSendEvent[daId] = []
    this.unSendEvent[daId].push({ target, daId, sendType, bindData, extraData })
  }

  executeEventInQueue({ daId = null, handler = null, sendType = [] } = {}) {
    if (!this.has.call(this.unSendEvent, daId) || this.unSendEvent[daId].length <= 0) return
    let i = this.unSendEvent[daId].length
    while(i > 0) {
      let event = this.unSendEvent[daId].shift()
      if (sendType.includes(event.sendType)) {
        handler({ target: event.target, extraData: event.extraData, bindData: event.bindData })
      }
      --i
    }
  }
}
